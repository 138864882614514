var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseBox',{staticClass:"grid gap-4 md:grid-cols-3"},[_c('BaseBox',{attrs:{"tag":"header"}},[_c('BaseHeading',{staticClass:"text-lg font-bold",attrs:{"level":2}},[_vm._v(" Edición de manómetro ")]),_c('BaseText',{attrs:{"tag":"p"}},[_vm._v(" Esta información afecta el manómetro de la página de Inicio. ")])],1),_c('ValidationObserver',{attrs:{"slim":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{ref:"form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.update)},"reset":function($event){$event.preventDefault();return reset($event)}}},[_c('BaseBox',{staticClass:"bg-white dark:bg-gray-800 shadow rounded-md overflow-hidden"},[_c('BaseBox',{staticClass:"grid gap-6 p-6"},[_c('ValidationProvider',{attrs:{"name":"Valor actual","rules":{
              required: true,
              integer: true,
              min: 0,
            },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('BaseFieldLabel',{attrs:{"html-for":"current-value","label":"Valor actual"}}),_c('BaseInputText',{attrs:{"id":"current-value","type":"number"},model:{value:(_vm.model.currentValue),callback:function ($$v) {_vm.$set(_vm.model, "currentValue", $$v)},expression:"model.currentValue"}}),_c('BaseFieldMessage',{attrs:{"id":"current-value-help","message":"Este valor es el que se muestra en la ilustración del manómetro."}}),_c('BaseFieldMessage',{attrs:{"id":"current-value-message","message":errors[0],"tone":"critical"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Incremento diario","rules":{
              required: true,
              integer: true,
              min: 0,
            },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('BaseFieldLabel',{attrs:{"html-for":"increment-by","label":"Incremento diario"}}),_c('BaseInputText',{attrs:{"id":"increment-by","type":"number"},model:{value:(_vm.model.incrementBy),callback:function ($$v) {_vm.$set(_vm.model, "incrementBy", $$v)},expression:"model.incrementBy"}}),_c('BaseFieldMessage',{attrs:{"id":"current-value-message","message":errors[0],"tone":"critical"}}),_c('BaseFieldMessage',{attrs:{"id":"current-value-help","message":"Diariamente, este número se le agregará automáticamente al valor actual del manómetro."}}),_c('BaseFieldMessage',{attrs:{"id":"current-value-message","message":errors[0],"tone":"critical"}})]}}],null,true)})],1),_c('BaseBox',{staticClass:"text-right bg-gray-50 dark:bg-gray-700 p-4"},[_c('BaseButton',{attrs:{"loading":_vm.isUpdating,"tone":"secondary","type":"submit"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('i18n',{attrs:{"tag":false,"path":"update"}})]},proxy:true},{key:"loading-text",fn:function(){return [_c('i18n',{attrs:{"tag":false,"path":"updateLoading"}})]},proxy:true}],null,true)})],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
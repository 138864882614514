





































































































import { defineComponent, onMounted, reactive, ref } from '@vue/composition-api'
import {
  manometerService,
  ManometerUpdateModel,
} from '@/services/modules/manometer'
import communique from '@/notification'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default defineComponent({
  name: 'Manometer',

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  setup() {
    const isLoadingValues = ref(false)
    const isUpdating = ref(false)

    const model = reactive<ManometerUpdateModel>({
      currentValue: 0,
      incrementBy: 0,
    })

    async function update() {
      try {
        isUpdating.value = true

        await manometerService.update({
          currentValue:
            typeof model.currentValue === 'string'
              ? Number.parseInt(model.currentValue)
              : model.currentValue,
          incrementBy:
            typeof model.incrementBy === 'string'
              ? Number.parseInt(model.incrementBy)
              : model.incrementBy,
        })

        communique.dispatch({
          variant: 'success',
          message: 'Los valores del manómetro se editaron.',
        })
      } catch (error) {
        communique.dispatch({
          variant: 'error',
          message:
            'Ocurrió un error al intentar editar los valores del manómetro.',
        })
      } finally {
        isUpdating.value = false
      }
    }

    async function getManometerValues() {
      try {
        isLoadingValues.value = true

        const {
          data: { value, incrementBy },
        } = await manometerService.get()

        model.currentValue = value
        model.incrementBy = incrementBy
      } catch (error) {
        communique.dispatch({
          variant: 'error',
          message:
            'Ocurrió un error al intentar obtener los valores del manómetro.',
        })
      } finally {
        isLoadingValues.value = false
      }
    }

    onMounted(async () => {
      await getManometerValues()
    })

    return {
      model,
      isUpdating,
      update,
    }
  },
})
